import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'


import Layout from '@components/layout/Layout'
import PropTypes from 'prop-types'
import BrightcoveVideoComponent from '@components/brightcove-video/brightcove-video.component'
import { videoLists } from '@components/video-library/videos'
import VideoItemComponent from '@components/video-library/video-item.component'
import ExpertPdf from '@assets/pdfs/narcolepsy-sodium-reduction.pdf'
import LeavingSiteModal from '@components/leaving-site-modal/leaving-site-modal.component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { trackGa } from '@components/GoogleTracking'

import ExpertPdfThumbnail from '@assets/images/narcolepsy-sodium-reduction-pdf.png'

import './expert-insights.scss'

const ExpertInsights = ({location}) => {
  /** @type {[int, function]} */
  const [activeTab, setActiveTab] = useState()
  /** @type {[{ show: boolean, href: string }, function]} */
  const [exitModal, setExitModal] = useState({ show: false, href: '' })

  useEffect(() => {
    document.body.style.overflowY = 'scroll';
    window.scrollTo(0, 0) //Reset scroll to top of page when navigating from carousel.

    setTimeout(() => {
      const expertPresentations = document.querySelector('#expert-presentations');
      if (location.hash === '#expert-presentations' && expertPresentations !== null) {
        window.scroll({ top: expertPresentations.offsetTop - 100, behavior: 'smooth' })
      }
    }, 100)

    return () => {
      document.body.style.overflowY = 'auto';
    }
  }, [])

  useEffect(() => {
    const activeTabIndex = new URL(location.href).searchParams.get("activeTabIndex");
    if (activeTabIndex == 1) {
      setActiveTab(1);
    }
    else if (activeTabIndex == 2) {
      setActiveTab(2);
    }
    else {
      setActiveTab(0);
    }
  }, [location])

  const handleTabChange = (e) => {
    var newActiveGroup = e.target.value ?? e.target.ActiveGroup ?? e.target.getAttribute("data-group");
    setActiveTab(parseInt(newActiveGroup))
    trackGa('expert insights, click, additional perspectives - expert insights')
  }

  const handleHideModal = () => {
    setTimeout(() => {
      setExitModal({ show: false, href: '' })
    }, 500)
  }

  const openLink = (link) => {
    setExitModal({ show: true, href: link })
  }

  const videoItemMobile = (data, key) => {
    // Adds deep link to expert insight videos. Scroll is fixed on scss page.
    let anchorLinkId = null

    if (data.deepLinkId) {
      anchorLinkId = data.deepLinkId
    } else {
      anchorLinkId = null
    }

    return (
      <div className="video-item" key={key} id={anchorLinkId}>
        <div className="video-mobile-wrapper">
          <BrightcoveVideoComponent
            player={data.player}
            account={data.account}
            videoId={data.videoId}
            tracking={data.tracking}
            trackPercentComplete={data.trackPercentComplete}
            src={data.src}
            isForMobile={true}
          />
        </div>
        <div className="video-title">
          { data.title }
        </div>
      </div>
    )
  }

    return (
      <Layout addedClass='page-narcolepsy-expert-insights' location={location}>
      <div className="primary-container">
        <h1 className="main-page-header">Expert Clinical Insights</h1>
      </div>
      <div className="insights-tab-titles">
        <div className="primary-container">
          <div className={`insights-tab-title ${activeTab == 0 && 'active'}`} data-group={0} onClick={handleTabChange}>Videos</div>
          <div className={`insights-tab-title ${activeTab == 1 && 'active'}`} data-group={1} onClick={handleTabChange}>Podcasts</div>
          <div className={`insights-tab-title ${activeTab == 2 && 'active'}`} data-group={2} onClick={handleTabChange}>Additional Perspectives</div>
        </div>
      </div>
      <div className="insights-tab-titles-mobile">
        <div className="primary-container">
          <p className="mobile-options-description">Select from the options in dropdown menu below.</p>
          <select className="mobile-options-select" onChange={handleTabChange}>
            <option value={0} data-group={0} selected={activeTab == 0}>Videos</option>
            <option value={1} data-group={1} selected={activeTab == 1}>Podcasts</option>
            <option value={2} data-group={2} selected={activeTab == 2}>Additional Perspectives</option>
          </select>
        </div>
      </div>
      <div className="insights-tab-content-container">
        <div className="primary-container">
          {/* Videos */}
          { activeTab == 0 &&
            <div className="insights-tab-container insight-videos-tab-container" data-group={0} >
              <div className="insights-tab-section interview-clips-container">
                <h3 className="insights-tab-section-title">Expert Interview Clips:</h3>
                <p className="insights-tab-description">Watch experts answer relevant questions about narcolepsy diagnosis, risk factors for comorbidities, and lifestyle modifications for long-term disease management.</p>
                <div className="insights-tab-media-container">
                  <div className="insights-video-list">
                      { 
                        videoLists.interviewClips.map((data, i) => <VideoItemComponent data={data} key={i} render={activeTab == 0}/>)
                      }
                  </div>
                  <div className="insights-video-list-mobile">
                      {
                        videoLists.interviewClips.map(videoItemMobile)
                      }
                  </div>
                </div>
              </div>
              <div id="expert-presentations" className="insights-tab-section expert-presentations-container">
                <h3 className="insights-tab-section-title">Expert Presentations:</h3>
                <p className="insights-tab-description">Watch experts describe narcolepsy, its symptoms, associated comorbidities, and the potential long-term health implications for your patients.</p>
                <div className="insights-tab-media-container">
                  <div className="insights-video-list">
                    {
                      videoLists.educationOnDemand.map((data, i) => <VideoItemComponent data={data} key={i} render={activeTab == 0}/>)
                    }
                  </div>
                  <div className="insights-video-list-mobile">
                    {
                      videoLists.educationOnDemand.map(videoItemMobile)
                    }
                  </div>
                </div>
              </div>
            </div>
          }
          {/* Podcasts */}
          { activeTab == 1 &&
            <div className="insights-tab-container podcasts-container" data-group={1}>
              <div className="insights-tab-section clinical-perspectives-container">
                <p className="insights-tab-description">
                    Listen as experts share clinical insights and best practices about key issues in managing patients with narcolepsy. These podcast episodes are produced and hosted on Sleep Review, with links below. 
                </p>
                <div className="podcasts-row podcasts-row--first">
                    <div className="podcast-item">
                        <div className="podcast-item__thumbnail">
                            <StaticImage src={'../../../assets/images/thumbnails/podcast-dr-thorpy.png'} width={256} quality={98} alt="6 hexagons form a circle listing rates of comorbid conditions" />
                        </div>
                        <h3 className="podcast-item__title">The Lifelong Burden of Narcolepsy</h3>
                        <div className="podcast-item__description">
                            <p><strong>Dr Michael Thorpy</strong> explores long-term management implications of narcolepsy, including</p>
                            <ul>
                                <li>How and when key symptoms typically manifest</li>
                                <li>How symptoms can evolve over a lifetime</li>
                                <li>Determining how and when management plans may need to be adjusted</li>
                            </ul>
                        </div>
                        <a className="podcast-item__listen-btn" onClick={() => openLink('https://sleepreviewmag.com/sleep-disorders/hypersomnias/narcolepsy/narcolepsy-across-the-lifespan/')}>
                            Listen <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                        </a>
                    </div>
                    <div className="podcast-item">
                        <div className="podcast-item__thumbnail">
                            <StaticImage src={'../../../assets/images/thumbnails/podcast-dr-bogan.png'} width={256} quality={98} alt="6 hexagons form a circle listing rates of comorbid conditions" />
                        </div>
                        <h3 className="podcast-item__title">The Balancing Act of Narcolepsy Management Plans</h3>
                        <div className="podcast-item__description">
                            <p><strong>Dr Richard Bogan</strong> discusses</p>
                            <ul>
                                <li>Considerations for comprehensive management of narcolepsy</li>
                                <li>Best practices for monitoring and managing narcolepsy-related comorbidities</li>
                                <li>Sleep lifestyle changes recommended for patients with narcolepsy</li>
                            </ul>
                        </div>
                        <a className="podcast-item__listen-btn" onClick={() => openLink('https://sleepreviewmag.com/sleep-disorders/hypersomnias/narcolepsy/developing-a-narcolepsy-management-plan/')}>
                            Listen <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                        </a>
                    </div>
                    <div className="podcast-item">
                        <div className="podcast-item__thumbnail">
                            <StaticImage src={'../../../assets/images/thumbnails/podcast-dr-winter.png'} width={256} quality={98} alt="6 hexagons form a circle listing rates of comorbid conditions" />
                        </div>
                        <h3 className="podcast-item__title">Comorbidities in Patients With Narcolepsy</h3>
                        <div className="podcast-item__description">
                            <p><strong>Dr W. Chris Winter</strong> reviews comorbidities associated with narcolepsy, focusing on</p>
                            <ul>
                                <li>Comorbidities that are more prevalent in patients with narcolepsy compared with controls</li>
                                <li>Screening for other health conditions</li>
                                <li>Best practices for monitoring and managing narcolepsy-related comorbidities</li>
                            </ul>
                        </div>
                        <a className="podcast-item__listen-btn" onClick={() => openLink('https://sleepreviewmag.com/sleep-disorders/hypersomnias/narcolepsy/handling-comorbidities-linked-with-narcolepsy/')}>
                            Listen <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                        </a>
                    </div>
                </div>
                <div className="podcasts-row">
                    <div className="podcast-item">
                        <div className="podcast-item__thumbnail">
                            <StaticImage src={'../../../assets/images/thumbnails/podcast-dr-kwon.png'} width={256} quality={98} alt="6 hexagons form a circle listing rates of comorbid conditions" />
                        </div>
                        <h3 className="podcast-item__title">Cardiovascular Comorbidities in Patients With Narcolepsy</h3>
                        <div className="podcast-item__description">
                            <p><strong>Dr Younghoon Kwon</strong> shares insights about the cardiovascular burden among patients with narcolepsy, discussing:</p>
                            <ul>
                                <li>Potential link between underlying pathophysiology of narcolepsy and comorbidities</li>
                                <li>Increased risk of certain cardiovascular conditions in patients with narcolepsy</li>
                                <li>Consideration of cardiovascular risk factors when determining a management plan for patients with narcolepsy</li>
                            </ul>
                        </div>
                        <a className="podcast-item__listen-btn" onClick={() => openLink('https://sleepreviewmag.com/resource-center/podcasts/reducing-risk-of-cardiovascular-comorbidities-in-people-with-narcolepsy/')}>
                            Listen <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                        </a>
                    </div>
                    <div className="podcast-item">
                        <div className="podcast-item__thumbnail">
                            <StaticImage src={'../../../assets/images/thumbnails/podcast-dr-hutchinson.png'} width={256} quality={98} alt="6 hexagons form a circle listing rates of comorbid conditions" />
                        </div>
                        <h3 className="podcast-item__title">Sodium Intake in Patients With Sleep Disorders</h3>
                        <div className="podcast-item__description">
                            <p><strong>Dr Barbara Hutchinson</strong> explores sodium intake as it relates to cardiovascular health, including</p>
                            <ul>
                                <li>The risks posed by high sodium intake</li>
                                <li>Sources of sodium intake</li>
                                <li>Discussing cardiovascular risks when determining a management plan for sleep disorders</li>
                                <li>Other modifiable cardiovascular risk factors beyond sodium intake</li>
                            </ul>
                        </div>
                        <a className="podcast-item__listen-btn" onClick={() => openLink('https://sleepreviewmag.com/sleep-disorders/hypersomnias/narcolepsy/sodium-intake-and-other-modifiable-cardiovascular-risk-factors/')}>
                            Listen <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                        </a>
                    </div>
                </div>
              </div>
            </div>
          }
          {/* Additional Clinical Perspectives Content */}
          { activeTab == 2 &&
            <div className="insights-tab-container additional-perspectives-container" data-group={1}>
              <div className="insights-tab-section clinical-perspectives-container">
                <p className="insights-tab-description">
                  Access in-depth materials on narcolepsy comorbidities, risk factors, management considerations, and more.
                </p>
                <div className="insights-tab-media-container expert-perspectives-container">
                  <div className="expert-container-left">
                    <img className="expert-pdf" src={ExpertPdfThumbnail} height="195px" width="150px" />
                  </div>
                  <div className="expert-container-right">
                    <h1 className="insights-tab-section-title lg-font">
                      The Importance of Sodium Reduction in Patients with Narcolepsy
                    </h1>
                    <p className="insights-tab-description description-margin">
                      Experts in sleep medicine and cardiology share insights about the role of sodium and other factors in modifying cardiometabolic and cardiovascular risk in patients with narcolepsy.
                    </p>
                    <div className="expert-perspectives-cta-container">
                      <a className='view-pdf-btn-wrapper' href={ExpertPdf} target="_blank">
                        <button
                          className="btn-standard view-pdf-btn"
                          onClick={() => {
                            trackGa('expert insights, click, view - the importance of sodium reduction in patients with narcolepsy')
                          }}
                        >
                          View
                        </button>
                      </a>
                      <a className='download-pdf-btn-wrapper' href={ExpertPdf} download>
                        <button
                          className="btn-standard download-pdf-btn"
                          onClick={() => {
                            trackGa('expert insights, click, download - the importance of sodium reduction in patients with narcolepsy')
                          }}
                        >
                          Download
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <LeavingSiteModal
          isShowModal={exitModal.show}
          onClose={handleHideModal}
          href={exitModal.href}
        />
    </Layout>
    )
}

export default ExpertInsights
