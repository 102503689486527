import React from 'react'

import './video-item.component.scss'

import PropTypes from 'prop-types'

import VideoModalComponent from './video-modal.component'

class VideoItemComponent extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      anchorLinkId: null
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
  }

  componentDidMount() {
    if (window.innerWidth > 750) {
      if (window.location.href.indexOf(this.props.data.deepLinkId) > -1) {
        this.setState({
          anchorLinkId: this.props.data.deepLinkId
        }, () => {
          const modal = document.getElementById(this.state.anchorLinkId)
          modal.click()
        })
      }
    }
  }

  handleOpenModal (e) {
    var modalComponent = e.target.querySelector('.video-modal-wrapper')
    if(!modalComponent.classList.contains('active'))
      modalComponent.classList.add('active')
  }

  render () {
    return (
      <div className="video-item" id={this.state.anchorLinkId} onClick={(e) => this.handleOpenModal(e) }>
        <VideoModalComponent data={this.props.data} /> 
        <div className="video-thumbnail-wrapper">
          <img src={this.props.data.src} />
        </div>
        <div className="video-title">
          { this.props.data.title }
        </div>
      </div>
    );
  }
}

VideoItemComponent.propTypes = {
  data: PropTypes.object.isRequired
}
export default VideoItemComponent
